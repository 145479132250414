html, body {
  background: black;
  color: #00b800;
  margin:0;
  padding: 0;
  padding-top: 10px;
  font-family: 'Press Start 2P', cursive;
  text-align: center;
}

body {
  font-weight: 400;
}
